var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',[_c('h5',{staticClass:"title",attrs:{"slot":"header"},slot:"header"},[_vm._v(" Create Store ")]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form-horizontal",attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createStore)}}},[_c('form-select',{attrs:{"title":"Shop Type","model-element":_vm.store.selectedType,"value-option":"id","options":_vm.store.type},on:{"update:modelElement":function($event){return _vm.$set(_vm.store, "selectedType", $event)},"update:model-element":function($event){return _vm.$set(_vm.store, "selectedType", $event)}}}),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v(" Name ")]),_c('div',{staticClass:"col-sm-10"},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The Name field is required' : null,"has-success":passed,"name":"name"},model:{value:(_vm.store.name),callback:function ($$v) {_vm.$set(_vm.store, "name", $$v)},expression:"store.name"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v(" Domain ")]),_c('div',{staticClass:"col-sm-10"},[_c('ValidationProvider',{attrs:{"name":"domain","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The Domain field is required' : null,"has-success":passed,"name":"domain"},model:{value:(_vm.store.domain),callback:function ($$v) {_vm.$set(_vm.store, "domain", $$v)},expression:"store.domain"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v(" Api Key ")]),_c('div',{staticClass:"col-sm-10"},[_c('fg-input',{attrs:{"type":"text","name":"api_key"},model:{value:(_vm.store.apiKey),callback:function ($$v) {_vm.$set(_vm.store, "apiKey", $$v)},expression:"store.apiKey"}})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v(" Api Secret ")]),_c('div',{staticClass:"col-sm-10"},[_c('fg-input',{attrs:{"type":"text","name":"api_secret"},model:{value:(_vm.store.apiSecret),callback:function ($$v) {_vm.$set(_vm.store, "apiSecret", $$v)},expression:"store.apiSecret"}})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v("Users")]),_c('div',{staticClass:"col-sm-10"},[_c('auto-complete',{ref:"autoComplete",attrs:{"selected-items":_vm.selectedUsers,"set-item":_vm.callSetUser,"gql-query":_vm.callUserQuery,"fetch-more":_vm.callFetchMore,"result":['firstname', 'lastname']}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-2 col-form-label"}),_c('div',{staticClass:"col-sm-10"},_vm._l((_vm.selectedUsers),function(tag,index){return _c('el-tag',{key:tag.id,attrs:{"type":"info","size":"small","closable":true},on:{"close":function($event){return _vm.callHandleClose(index)}}},[_vm._v(" "+_vm._s(tag.firstname)+" "+_vm._s(tag.lastname)+" ")])}),1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('button',{staticClass:"btn btn-success",attrs:{"type":"submit"}},[_vm._v(" Create ")])])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }