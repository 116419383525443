<template>
  <div>
    <table-page
      ref="childData"
      title="Stores"
      :get-data="getStores"
      :loading="loading">
      <el-table
        stripe
        style="width: 100%;"
        :data="stores">
        <el-table-column
          width="100"
          prop="_id"
          label="ID">
          <template #default="scope">
            {{ scope.row._id }}
          </template>
        </el-table-column>
        <el-table-column
          prop="Name"
          label="Name">
          <template #default="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column
          prop="Domain"
          label="Domain">
          <template #default="scope">
            {{ scope.row.domain }}
          </template>
        </el-table-column>

        <el-table-column
          width="200"
          fixed="right"
          header-align="right"
          label="Actions">
          <div
            slot-scope="props"
            class="text-right table-actions">
            <el-tooltip
              content="Select"
              :open-delay="300"
              placement="top">
              <n-button
                type="success"
                size="sm"
                icon
                @click.native="handleSelect(props.$index, props.row)">
                <i class="now-ui-icons gestures_tap-01"></i>
              </n-button>
            </el-tooltip>

            <el-tooltip
              content="Show"
              :open-delay="300"
              placement="top">
              <n-button
                type="info"
                size="sm"
                icon
                @click.native="handleShow(props.$index, props.row)">
                <i class="now-ui-icons users_single-02"></i>
              </n-button>
            </el-tooltip>

            <el-tooltip
              content="Edit"
              :open-delay="300"
              placement="top">
              <n-button
                type="warning"
                size="sm"
                icon
                @click.native="handleEdit(props.$index, props.row)">
                <i class="now-ui-icons ui-1_settings-gear-63"></i>
              </n-button>
            </el-tooltip>

            <el-tooltip
              content="Delete"
              :open-delay="300"
              placement="top">
              <n-button
                type="danger"
                size="sm"
                icon
                @click.native="handleDelete(props.$index, props.row)">
                <i class="now-ui-icons ui-1_simple-remove"></i>
              </n-button>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </table-page>
  </div>
</template>

<script>
  import { Table, TableColumn } from 'element-ui';
  import TablePage from '@/components/TablePage.vue';
  import StoresQuery from '@/graphql/stores/queries/Stores.gql';
  import DeleteStoreMutation from '@/graphql/stores/mutations/DeleteStoreMutation.gql';
  import Swal from 'sweetalert2';

  export default {
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      TablePage,
    },
    data() {
      return {
        loading: true,
        stores: [],
      };
    },
    mounted() {
      this.getStores();
    },
    methods: {
      async getStores(search) {
        try {
          const variables = {
            page: this.$refs.childData.pagination.page,
            itemsPerPage: this.$refs.childData.pagination.perPage,
          };

          if (search) variables.search = search;

          await this.$apollo.addSmartQuery('getStores', {
            query: StoresQuery,
            variables,
            manual: true,
            result({ data, loading }) {
              if (!loading) {
                this.loading = false;
                this.stores = data.stores.collection;
                this.$refs.childData.pagination.total = data.stores.paginationInfo.totalCount;
              }
            },
          });
        } catch (error) {
          console.log(error);
        }
      },
      async handleShow(index, event) {
        // eslint-disable-next-line no-underscore-dangle
        await this.$router.push({ name: 'stores.show', params: { storeId: event._id } });
        console.log('show');
      },
      async handleSelect(index, event) {
        await this.$store.dispatch('fetchStore', event.id);
        this.$root.notifyVue('info', `${event.name} is selected`);
      },
      async handleEdit(index, event) {
        // eslint-disable-next-line no-underscore-dangle
        await this.$router.push({ name: 'stores.edit', params: { storeId: event._id } });
      },
      async handleDelete(index, event) {
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: `You won't be able to revert this!`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success',
          cancelButtonClass: 'btn btn-danger',
          confirmButtonText: 'Yes, delete it!',
          buttonsStyling: false,
        });

        if (result.value) {
          try {
            await this.$apollo.mutate({
              mutation: DeleteStoreMutation,
              variables: { id: event.id },
            });

            await this.getStores();

            this.$root.notifyVue('danger', 'Store has been deleted!');
          } catch (error) {
            console.log(error);
          }
        }
      },
    },
  };
</script>
