<template>
  <div class="row">
    <label class="col-sm-2 col-form-label">
      {{ title }}
    </label>
    <div class="col-sm-10">
      <el-select
        :value="modelElement"
        class="select-primary"
        size="large"
        placeholder="select type"
        @input="event => $emit('update:modelElement', event)">
        <el-option
          v-for="option in options"
          :key="option.label"
          class="select-primary"
          :value="checkValue(option)"
          :label="option.label" />
      </el-select>
    </div>
  </div>
</template>

<script>
  import { Option } from 'element-ui';

  export default {
    components: {
      [Option.name]: Option,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      modelElement: {
        type: String,
        required: true,
      },
      options: {
        type: Array,
        required: true,
      },
      valueOption: {
        type: String,
        default: null,
      },
    },
    methods: {
      checkValue(value) {
        if (this.valueOption) {
          return value[this.valueOption];
        }

        return value.value;
      },
    },
  };
</script>
