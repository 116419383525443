<template>
  <div>
    <h6 class="ml-3 mt-3">
      {{ title }}
    </h6>

    <div class="row">
      <label class="col-sm-2 col-form-label"></label>
      <div class="col-sm-10 checkbox-radios">
        <checkbox v-model="sectionOption.checkbox.value">
          {{ sectionOption.checkbox.label }}
        </checkbox>
      </div>
    </div>

    <div
      v-for="item in sectionOption.input"
      :key="item.key"
      class="row">
      <label class="col-sm-2 col-form-label">
        {{ item.label }}
      </label>
      <div class="col-sm-10">
        <fg-input
          v-model.number="item.value"
          :disabled="!sectionOption.checkbox.value"
          type="number" />
      </div>
    </div>

    <hr>
  </div>
</template>

<script>
  import Checkbox from '@/components/Inputs/Checkbox.vue';

  export default {
    components: {
      Checkbox,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      sectionOption: {
        type: Object,
        required: true,
      },
    },
  };
</script>
