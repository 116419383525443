import GetSelectedUser from '@/graphql/users/queries/GetSelectedUser.gql';
import Users from '@/graphql/users/queries/Users.gql';

const setUser = async (_self, user) => {
  try {
    await _self.$apollo.addSmartQuery('getUserById', {
      query: GetSelectedUser,
      fetchPolicy: 'no-cache',
      variables: {
        id: user.id,
      },
      manual: true,
      result({ data, loading }) {
        if (!loading) {
          const duplicate = _self.selectedUsers.some(element => element.id === data.user.id);

          if (!duplicate) {
            _self.selectedUsers.push({
              id: data.user.id,
              firstname: data.user.firstname,
              lastname: data.user.lastname,
            });
          }
        }
      },
    });
  } catch (error) {
    console.log(error);
  }

  _self.$refs.autoComplete.modal = false;
};

const userQuery = async (_self, variables) => {
  try {
    await _self.$apollo.addSmartQuery('setAllUsers', {
      query: Users,
      variables,
      manual: true,
      result({ data, loading }) {
        if (!loading) {
          _self.$refs.autoComplete.items = data.users.collection;
          _self.$refs.autoComplete.lastPage = data.users.paginationInfo.lastPage;

          if (!data.users.collection.length) {
            _self.$refs.autoComplete.noResults = true;
          }
        }
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const fetchMore = async (_self, variables, oldData) => {
  await _self.$apollo.queries.setAllUsers.fetchMore({
    variables,
    updateQuery: (previousResult, { fetchMoreResult }) => {
      _self.$refs.autoComplete.items = [...oldData, ...fetchMoreResult.users.collection];

      if (_self.$refs.autoComplete.page === _self.$refs.autoComplete.lastPage) {
        _self.$refs.autoComplete.showLoadMoreButton = false;
      }
    },
  });
};

const handleClose = async (_self, id) => {
  await _self.selectedUsers.splice(id, 1);
};

export {
  setUser, userQuery, fetchMore, handleClose,
};
