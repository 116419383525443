<template>
  <div>
    <loading-panel v-if="loading" />
    <div
      v-else
      class="row"
      style="margin-top: 60px">
      <div class="col-12">
        <h2>STORE</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-stats card-raised">
          <div class="card-body">
            <div class="row">
              <ul>
                <li v-for="item in store">
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import LoadingPanel from '@/components/LoadingPanel.vue';
  import GetStoreById from '@/graphql/stores/queries/GetStoreById.gql';

  export default {
    components: {
      LoadingPanel,
    },
    data() {
      return {
        loading: true,
        store: [],
      };
    },
    created() {
      this.getCurrentStore();
    },
    methods: {
      async getCurrentStore() {
        try {
          await this.$apollo.addSmartQuery('GetStoreById', {
            query: GetStoreById,
            variables: {
              id: `api/stores/${this.$route.params.storeId}`,
            },
            manual: true,
            result({ data, loading }) {
              if (!loading) {
                this.loading = false;
                this.store = data.store;
              }
            },
          });
        } catch (error) {
          console.log(error);
        }
      },
    },
  };
</script>
