function initialState() {
  return {
    noResults: true,
    loading: true,
    editMode: false,
    editLinkId: null,
    feeds: [],
    urlParameters: '',
    defaults: [
      {
        key: 'minimalMargin',
        label: 'Minimal Marge',
        value: null,
      },
      {
        key: 'minimalProfitMargin',
        label: 'Minimal profit margin',
        value: null,
      },
      {
        key: 'minimalSold',
        label: 'Minimal sold',
        value: null,
      },
    ],
    selects: {
      types: [
        { value: 'xml', label: 'XML' },
        { value: 'rss', label: 'rss' },
      ],
      adTypes: [
        { value: 'google', label: 'Google' },
        { value: 'facebook', label: 'Facebook' },
      ],
    },
    selectedLanguage: 'nl',
    selectedType: 'xml',
    selectedAdType: 'google',
    checkboxes: [
      {
        key: 'withoutCostPrice',
        label: 'Without cost price',
        value: false,
      },
      {
        key: 'withoutStock',
        label: 'Without stock',
        value: false,
      },
      {
        key: 'onlyDefaultVariants',
        label: 'Only default variants',
        value: false,
      },
    ],

    revenue: {
      input: [
        {
          key: 'revenueTopProducts',
          label: 'Revenue top products',
          value: null,
        },
        {
          key: 'revenuePercentage',
          label: 'Revenue percentage',
          value: null,
        },
        {
          key: 'revenueCountDays',
          label: 'Revenue count days',
          value: null,
        },
      ],
      checkbox: {
        key: 'productsBasedOnRevenue',
        label: 'Products based on revenue',
        value: false,
      },
    },
    prio: {
      input: [
        {
          key: 'prioTopProducts',
          label: 'Prio top products',
          value: null,
        },
        {
          key: 'prioPercentage',
          label: 'Prio percentage',
          value: null,
        },
        {
          key: 'prioCountDays',
          label: 'Prio count days',
          value: null,
        },
      ],
      checkbox: {
        key: 'prioBasedOnRevenue',
        label: 'Prio based on revenue',
        value: false,
      },
    },
  };
}

export default initialState;
