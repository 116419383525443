<template>
  <card>
    <h5
      slot="header"
      class="title">
      Create Store
    </h5>
    <ValidationObserver
      ref="observer"
      v-slot="{ handleSubmit }">
      <form
        method="post"
        class="form-horizontal"
        @submit.prevent="handleSubmit(createStore)">
        <form-select
          title="Shop Type"
          :model-element.sync="store.selectedType"
          value-option="id"
          :options="store.type" />

        <div class="row">
          <label class="col-sm-2 col-form-label">
            Name
          </label>
          <div class="col-sm-10">
            <ValidationProvider
              v-slot="{ passed, failed }"
              name="name"
              rules="required">
              <fg-input
                v-model="store.name"
                type="text"
                :error="failed ? 'The Name field is required' : null"
                :has-success="passed"
                name="name" />
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-2 col-form-label">
            Domain
          </label>
          <div class="col-sm-10">
            <ValidationProvider
              v-slot="{ passed, failed }"
              name="domain"
              rules="required">
              <fg-input
                v-model="store.domain"
                type="text"
                :error="failed ? 'The Domain field is required' : null"
                :has-success="passed"
                name="domain" />
            </ValidationProvider>
          </div>
        </div>

        <div class="row">
          <label class="col-sm-2 col-form-label">
            Api Key
          </label>
          <div class="col-sm-10">
            <fg-input
              v-model="store.apiKey"
              type="text"
              name="api_key" />
          </div>
        </div>

        <div class="row">
          <label class="col-sm-2 col-form-label">
            Api Secret
          </label>
          <div class="col-sm-10">
            <fg-input
              v-model="store.apiSecret"
              type="text"
              name="api_secret" />
          </div>
        </div>

        <div class="row">
          <label class="col-sm-2 col-form-label">Users</label>
          <div class="col-sm-10">
            <auto-complete
              ref="autoComplete"
              :selected-items="selectedUsers"
              :set-item="callSetUser"
              :gql-query="callUserQuery"
              :fetch-more="callFetchMore"
              :result="['firstname', 'lastname']" />
          </div>
        </div>

        <div class="row">
          <div class="col-sm-2 col-form-label"></div>
          <div class="col-sm-10">
            <el-tag
              v-for="(tag, index) in selectedUsers"
              :key="tag.id"
              type="info"
              size="small"
              :closable="true"
              @close="callHandleClose(index)">
              {{ tag.firstname }} {{ tag.lastname }}
            </el-tag>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <button
              type="submit"
              class="btn btn-success">
              Create
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </card>
</template>

<script>
  import { Tag } from 'element-ui';
  import AutoComplete from '@/components/AutoComplete.vue';
  import formSelect from '@/modules/Dashboard/Feed/components/form-select.vue';
  import { extend } from 'vee-validate';
  import { email, required } from 'vee-validate/dist/rules';
  import CreateStoreMutation from '@/graphql/stores/mutations/CreateStoreMutation.gql';
  import GetStoreTypes from '@/graphql/stores/queries/GetStoreTypes.gql';
  import {
    setUser, userQuery, fetchMore, handleClose,
  } from '@/modules/Dashboard/Users/currentUser';

  extend('email', email);
  extend('required', required);

  function initialState() {
    return {
      store: {
        name: '',
        domain: '',
        type: [],
        selectedType: '',
        apiKey: '',
        apiSecret: '',
      },

      selectedUsers: [],
    };
  }

  export default {
    components: {
      AutoComplete,
      formSelect,
      [Tag.name]: Tag,
    },
    data() {
      return initialState();
    },
    created() {
      this.getStoreTypes();
    },
    methods: {
      async getStoreTypes() {
        this.$apollo.addSmartQuery('getStoreTypes', {
          query: GetStoreTypes,
          manual: true,
          result({ data, loading }) {
            if (!loading) {
              this.store.type = data.storeTypes.collection;
            }
          },
        });
      },
      async createStore() {
        try {
          const users = [];

          if (this.selectedUsers) {
            for (const value of Object.values(this.selectedUsers)) {
              users.push(value.id);
            }
          }

          await this.$apollo.mutate({
            mutation: CreateStoreMutation,
            variables: {
              name: this.store.name,
              domain: this.store.domain,
              api_key: this.store.apiKey,
              api_secret: this.store.apiSecret,
              store_type: this.store.selectedType,
              default_language: 'nl',
              users,
            },
          });

          Object.assign(this.$data, initialState());
          this.$refs.observer.reset();
          await this.getStoreTypes();

          this.$root.notifyVue('success', 'Store has been created!');
        } catch (error) {
          console.log(error);
        }
      },
      async callSetUser(item) {
        await setUser(this, item);
      },
      async callUserQuery(variables) {
        await userQuery(this, variables);
      },
      async callFetchMore(variables, oldData) {
        await fetchMore(this, variables, oldData);
      },
      async callHandleClose(id) {
        await handleClose(this, id);
      },
    },
  };
</script>
