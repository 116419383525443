<template>
  <div class="row">
    <div class="col-12">
      <card no-footer-line>
        <div slot="header">
          <div
            v-if="editMode"
            class="alert alert-warning">
            <button
              type="button"
              aria-hidden="true"
              class="close"
              @click="leaveEditMode">
              <i class="now-ui-icons ui-1_simple-remove"></i>
            </button>
            <span><b> Edit mode </b> You are currently in edit mode!</span>
          </div>
          <h4 class="card-title">
            Feed
          </h4>
        </div>

        <loading-panel v-if="loading" />

        <form
          v-else
          method="post"
          class="form-horizontal"
          @submit.prevent="submitForm">
          <div>
            <form-select
              title="Taal"
              :model-element.sync="selectedLanguage"
              value-option="code"
              :options="store.languages" />

            <form-select
              title="Type"
              :model-element.sync="selectedType"
              :options="selects.types" />

            <form-select
              title="Ad type"
              :model-element.sync="selectedAdType"
              :options="selects.adTypes" />

            <div
              v-for="item in defaults"
              :key="item.key"
              class="row">
              <label class="col-sm-2 col-form-label">
                {{ item.label }}
              </label>
              <div class="col-sm-10">
                <fg-input
                  v-model.number="item.value"
                  type="number" />
              </div>
            </div>

            <hr>
          </div>

          <form-section
            title="Revenue"
            :section-option="revenue" />
          <form-section
            title="Prio"
            :section-option="prio" />

          <div>
            <h6 class="ml-3 mt-3">
              Extra
            </h6>

            <div
              v-for="item in checkboxes"
              :key="item.key"
              class="row">
              <label class="col-sm-2 col-form-label"></label>
              <div class="col-sm-10 checkbox-radios">
                <checkbox v-model="item.value">
                  {{ item.label }}
                </checkbox>
              </div>
            </div>

            <hr>
          </div>

          <div class="row">
            <div class="col">
              <button
                type="submit"
                :class="[editMode ? 'btn-warning' : 'btn-primary']"
                class="btn btn-primary ml-3">
                {{ editMode ? "Edit" : "Save" }}
              </button>
              <button
                v-if="editMode"
                class="btn btn-grey ml-3"
                @click="leaveEditMode">
                Cancel
              </button>
            </div>
          </div>
        </form>
      </card>
    </div>

    <div class="col-12">
      <card
        card-body-classes="table-full-width"
        no-footer-line>
        <h4
          slot="header"
          class="card-title">
          Feed Links
        </h4>

        <loading-panel v-if="loading" />

        <!--    TODO: Check length of longest string and set that width    -->
        <el-table
          v-else-if="!loading"
          stripe
          style="width: 100%"
          :data="feeds.edges">
          <el-table-column
            prop="link"
            label="Link">
            <template #default="scope">
              <a
                :href="scope.row.node.url"
                target="_blank"
                class="scrollable-link">
                {{ scope.row.node.url }}
              </a>
            </template>
          </el-table-column>

          <el-table-column
            fixed="right"
            width="200"
            header-align="right"
            label="Actions">
            <div
              slot-scope="props"
              class="text-right table-actions">
              <el-tooltip
                content="Settings"
                :open-delay="300"
                placement="top">
                <n-button
                  type="warning"
                  size="sm"
                  icon
                  @click.native="setFormToEditLink(props.$index, props.row)">
                  <i class="now-ui-icons ui-1_settings-gear-63"></i>
                </n-button>
              </el-tooltip>

              <el-tooltip
                content="Copy"
                :open-delay="300"
                placement="top">
                <n-button
                  type="info"
                  size="sm"
                  icon
                  @click.native="copyLink(props.$index, props.row)">
                  <i class="now-ui-icons files_single-copy-04"></i>
                </n-button>
              </el-tooltip>

              <el-tooltip
                content="Delete"
                :open-delay="300"
                placement="top">
                <n-button
                  type="danger"
                  size="sm"
                  icon
                  @click.native="deleteLink(props.$index, props.row)">
                  <i class="now-ui-icons ui-1_simple-remove"></i>
                </n-button>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </card>
    </div>
  </div>
</template>
<script>
  import LoadingPanel from '@/components/LoadingPanel.vue';
  import { Checkbox } from 'src/components/index';
  import {
    Table, TableColumn, Option,
  } from 'element-ui';
  import Swal from 'sweetalert2';
  import { mapGetters } from 'vuex';
  import getFeedsBasedStore from '@/graphql/feed/queries/Feed.gql';
  import getFeedById from '@/graphql/feed/queries/GetFeedById.gql';
  import createFeedMutation from '@/graphql/feed/mutation/Create.gql';
  import updateFeedMutation from '@/graphql/feed/mutation/Update.gql';
  import deleteFeedMutation from '@/graphql/feed/mutation/Delete.gql';
  import formSelect from '../components/form-select.vue';
  import formSection from '../components/form-section.vue';
  import initialState from '../initialState/index';

  export default {
    components: {
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      Checkbox,
      formSelect,
      formSection,
      LoadingPanel,
    },
    data() {
      return initialState();
    },
    computed: {
      ...mapGetters(['store']),
    },
    watch: {
      store: {
        handler(store) {
          if (store) {
            this.getFeedsBasedStore();
          }
        },
        immediate: true,
      },
    },
    methods: {
      async copyLink(index, event) {
        await navigator.clipboard.writeText(event.node.url);
        this.$root.notifyVue('info', 'Url copied to clipboard!');
      },
      async getFeedsBasedStore() {
        try {
          await this.$apollo.addSmartQuery('getFeedsBasedStore', {
            query: getFeedsBasedStore,
            fetchPolicy: 'no-cache',
            variables: {
              // eslint-disable-next-line no-underscore-dangle
              store: this.store._id.toString(),
            },
            manual: true,
            result({ data, loading }) {
              if (!loading) {
                this.loading = false;
                this.feeds = data.feeds;
              }
            },
          });
        } catch (error) {
          console.log(error);
        }
      },
      addToUrlParameter(items, setOne) {
        items.forEach(item => {
          for (const [key, value] of Object.entries(item)) {
            if (key === 'key') {
              this.urlParameters += `&${value}=`;
            }

            if (key === 'value') {
              setOne ? this.urlParameters += 1 : this.urlParameters += value;
            }
          }
        });
      },
      createdFeedLink() {
        let url = 'https://feed.gmu.online/';

        const filteredDefaultsOnValue = this.defaults.filter(item => item.value);
        this.addToUrlParameter(filteredDefaultsOnValue);

        const checked = this.checkboxes.filter(item => item.value);
        this.addToUrlParameter(checked, true);

        const revenueValues = this.revenue.input.filter(item => item.value);
        if (this.revenue.checkbox.value) {
          this.urlParameters += `&${this.revenue.checkbox.key}=1`;
        }
        this.addToUrlParameter(revenueValues);

        const prioValues = this.prio.input.filter(item => item.value);
        if (this.prio.checkbox.value) {
          this.urlParameters += `&${this.prio.checkbox.key}=1`;
        }
        this.addToUrlParameter(prioValues);

        url += `?storeId=${this.$store.getters.store.external_id}&language=${this.selectedLanguage}&type=${this.selectedType}&adType=${this.selectedAdType}${this.urlParameters}`;

        return url;
      },
      getFeedVariables(isSet) {
        return {
          id: isSet ? this.editLinkId : null,
          shop_id: this.$store.getters.store.external_id,
          url: this.createdFeedLink(),
          store: this.$store.getters.store.id,
          language: this.selectedLanguage,
          type: this.selectedType,
          ad_type: this.selectedAdType,
          without_cost_price: this.checkboxes[0].value,
          without_stock: this.checkboxes[1].value,
          only_default_variants: this.checkboxes[2].value,
          products_based_on_revenue: this.revenue.checkbox.value,
          prio_based_on_revenue: this.prio.checkbox.value,
          minimal_margin: this.defaults[0].value ? this.defaults[0].value : null,
          minimal_profit_margin: this.defaults[1].value ? this.defaults[1].value : null,
          minimal_amount_sold: this.defaults[2].value ? this.defaults[2].value : null,
          revenue_top_products: this.revenue.input[0].value ? this.revenue.input[0].value : null,
          revenue_percentage: this.revenue.input[1].value ? this.revenue.input[1].value : null,
          revenue_count_days: this.revenue.input[2].value ? this.revenue.input[2].value : null,
          prio_top_products: this.prio.input[0].value ? this.prio.input[0].value : null,
          prio_percentage: this.prio.input[1].value ? this.prio.input[1].value : null,
          prio_count_days: this.prio.input[2].value ? this.prio.input[2].value : null,
        };
      },
      resetForm() {
        Object.assign(this.$data, initialState());
        this.getFeedsBasedStore();
      },
      leaveEditMode() {
        this.editMode = false;
        this.loading = false;
        this.resetForm();
      },
      submitForm() {
        return this.editMode ? this.updateLink() : this.createLink();
      },
      async setFormToEditLink(index, event) {
        try {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });

          this.editMode = true;

          await this.$apollo.addSmartQuery('getFeedById', {
            query: getFeedById,
            fetchPolicy: 'no-cache',
            variables: {
              id: event.node.id,
            },
            manual: true,
            result({ data }) {
              const item = data.feed;
              this.editLinkId = item.id;
              this.selectedLanguage = item.language;
              this.selectedType = item.type;
              this.selectedAdType = item.ad_type;
              this.checkboxes[0].value = item.without_cost_price;
              this.checkboxes[1].value = item.without_stock;
              this.checkboxes[2].value = item.only_default_variants;
              this.revenue.checkbox.value = item.products_based_on_revenue;
              this.prio.checkbox.value = item.prio_based_on_revenue;
              this.defaults[0].value = item.minimal_margin;
              this.defaults[1].value = item.minimal_profit_margin;
              this.defaults[2].value = item.minimal_amount_sold;
              this.revenue.input[0].value = item.revenue_top_products;
              this.revenue.input[1].value = item.revenue_percentage;
              this.revenue.input[2].value = item.revenue_count_days;
              this.prio.input[0].value = item.prio_top_products;
              this.prio.input[1].value = item.prio_percentage;
              this.prio.input[2].value = item.prio_count_days;
            },
          });
        } catch (error) {
          console.log(error);
        }
      },
      async createLink() {
        try {
          await this.$apollo.mutate({
            mutation: createFeedMutation,
            variables: this.getFeedVariables(),
          });

          await this.getFeedsBasedStore();

          this.$root.notifyVue('success', 'Link has been created!');
          this.resetForm();
          this.loading = false;
        } catch (error) {
          console.log(error);
        }
      },
      async updateLink() {
        try {
          await this.$apollo.mutate({
            mutation: updateFeedMutation,
            variables: this.getFeedVariables(this.editLinkId),
          });

          await this.getFeedsBasedStore();

          this.$root.notifyVue('warning', 'Link has been updated!');
          this.leaveEditMode();
        } catch (error) {
          console.log(error);
        }
      },
      async deleteLink(feedId) {
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: `You won't be able to revert this!`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success',
          cancelButtonClass: 'btn btn-danger',
          confirmButtonText: 'Yes, delete it!',
          buttonsStyling: false,
        });

        if (result.value) {
          try {
            await this.$apollo.mutate({
              mutation: deleteFeedMutation,
              variables: { id: feedId },
            });

            await this.getFeedsBasedStore();

            this.$root.notifyVue('danger', 'Link has been deleted!');
            this.leaveEditMode();
          } catch (error) {
            console.log(error);
          }
        }
      },
    },
  };
</script>

<style>
.scrollable-link {
  color: black;
  white-space: pre;
}
</style>
