<template>
  <div>
    <loading-panel v-if="loading" />

    <card v-else>
      <h5
        slot="header"
        class="title">
        Update Store
      </h5>

      <form
        method="post"
        class="form-horizontal"
        @submit.prevent="updateStore">
        <input-styled
          title="Name"
          :model-element.sync="store.name" />

        <input-styled
          title="Domain"
          :model-element.sync="store.domain" />

        <input-styled
          title="Api Key"
          :model-element.sync="store.api_key" />

        <input-styled
          title="Default Language"
          :model-element.sync="store.default_language" />

        <div class="row">
          <label class="col-sm-2 col-form-label">Users</label>
          <div class="col-sm-10">
            <auto-complete
              ref="autoComplete"
              :selected-items="selectedUsers"
              :set-item="callSetUser"
              :gql-query="callUserQuery"
              :fetch-more="callFetchMore"
              :result="['firstname', 'lastname']" />
          </div>
        </div>

        <div class="row">
          <div class="col-sm-2 col-form-label"></div>
          <div class="col-sm-10">
            <el-tag
              v-for="(tag, index) in selectedUsers"
              :key="tag.id"
              type="info"
              size="small"
              :closable="true"
              @close="callHandleClose(index)">
              {{ tag.firstname }} {{ tag.lastname }}
            </el-tag>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <button
              type="submit"
              class="btn btn-warning">
              Edit
            </button>
          </div>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
  import GetStoreById from '@/graphql/stores/queries/GetStoreById.gql';
  import UpdateStoreMutation from '@/graphql/stores/mutations/UpdateStoreMutation.gql';
  import LoadingPanel from '@/components/LoadingPanel.vue';
  import InputStyled from '@/components/InputStyled.vue';
  import AutoComplete from '@/components/AutoComplete.vue';
  import { Tag } from 'element-ui';
  import {
    setUser, userQuery, fetchMore, handleClose,
  } from '@/modules/Dashboard/Users/currentUser';

  export default {
    components: {
      LoadingPanel,
      InputStyled,
      AutoComplete,
      [Tag.name]: Tag,
    },
    data() {
      return {
        loading: true,
        store: {
          name: '',
          domain: '',
          api_key: '',
          default_language: '',
          store_type: '',
        },
        selectedUsers: [],
      };
    },
    created() {
      this.getCurrentStore();
    },
    methods: {
      async getCurrentStore() {
        try {
          await this.$apollo.addSmartQuery('getCurrentStore', {
            query: GetStoreById,
            variables: {
              id: `api/stores/${this.$route.params.storeId}`,
            },
            manual: true,
            result({ data, loading }) {
              if (!loading) {
                this.loading = false;
                this.store = data.store;

                this.selectedUsers = data.store.users.collection;
              }
            },
          });
        } catch (error) {
          console.log(error);
        }
      },
      async updateStore() {
        const users = [];

        if (this.selectedUsers) {
          for (const value of Object.values(this.selectedUsers)) {
            users.push(value.id);
          }
        }

        try {
          await this.$apollo.mutate({
            mutation: UpdateStoreMutation,
            variables: {
              id: `api/stores/${this.$route.params.storeId}`,
              name: this.store.name,
              domain: this.store.domain,
              api_key: this.store.api_key,
              default_language: this.store.default_language,
              users,
            },
          });

          this.$root.notifyVue('warning', 'Store has been updated!');
          await this.getCurrentStore();
        } catch (error) {
          console.log(error);
        }
      },
      async callSetUser(user) {
        await setUser(this, user);
      },
      async callUserQuery(variables) {
        await userQuery(this, variables);
      },
      async callFetchMore(variables, oldData) {
        await fetchMore(this, variables, oldData);
      },
      async callHandleClose(id) {
        await handleClose(this, id);
      },
    },
  };
</script>
